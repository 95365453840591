function combineStyles(...styles) {
    let ret = {};

    for (let i = 0; i < styles.length; i++)
        for (var prop in styles[i])
            ret[prop] = styles[i][prop];

    return ret;
}

const colors = {
    violet: "#70268a",
    pink: "#fd3e81",

    lightGreen: "#72e2a2",
    darkTurquoise: "#3e8187",

    lightRed: "#e36464",
    bordo: "#65262F",

    brown: "#3e231d",
    black: "#20303c",
    white: "#fff8f0",

    darkGray: "#e8e8e8",
    lightGray: "#f7f7f7"
}

const mainColor = (location.pathname.indexOf("appeal") === -1 ? colors.violet : colors.darkTurquoise);

console.log("in css.js.", location.pathname, mainColor);

const fadedOp = "0.7";

module.exports = {
    colors,
    mainColor,
    combineStyles,

    general: {

        noMarginPadding: {
            margin: "0px",
            padding: "0px"
        },
        faded: {
            opacity: fadedOp
        },
        expanded: {
            width: "100%",
            maxWidth: "100%",
            minWidth: "100%"
        },
        spaceDown: {
            marginBottom: "20px"
        }
    },

    // by topic
    fonts: {

        commonFont: {
            fontFamily: ["\"reg-font\"", "ariel", "sans-serif"],
            color: colors.black,
            fontSize: "18px",
            textAlign: "right",
            direction: "rtl"
        },
        title: {
            fontFamily: ["\"title-font\"", "ariel", "sans-serif"],
            fontSize: "55px",
            textAlign: "center",
            margin: "0px"
        },
        subtitle: {
            fontSize: "30px",
            color: "rgb(101,112,120)",
            // opacity: fadedOp
        },
        miniTitle: { // Should be combined with commonFont
            fontWeight: "bold",
            fontSize: "24px"
        },

    },

    // By component
    input: {
        padding: "0px 15px",
        lineHeight: "35px",
    },
    button: {
        backgroundColor: mainColor,
        padding: "8px",
        color: colors.white,
        borderRadius: "13px",
        border: "none",
        marginTop: "20px",
        textAlign: "center"
    },
    disabledButton: {
        backgroundColor: colors.brown,
        cursor: "not-allowed"
    },
    verdictDialog: {
        container: {
            backgroundColor: colors.lightGray,
            border: "2px solid " + colors.darkGray,
            padding: "20px",
            margin: "10px",
        },
        canceledInfo: {
            color: colors.lightRed,
            border: "2px solid " + colors.lightRed,
            padding: "20px",
            marginTop: "20px",
            textAlign: "center"
        
        }
    },
    infoBox: {
        maxWidth: "450px",
        borderRadius: "10px",
        backgroundColor: colors.darkGray,
        border: "2px solid " + colors.black,
        padding: "10px 13px",
        position: "fixed",
        zIndex: 10,
    },
    pageControl: {
        container: {
            border: "2px solid " + colors.brown,
            borderRight: "0px",
            padding: "0px"
        },
        regularNumber: {
            cursor: "pointer",
            padding: "0 10px",
            textAlign: "center",
            borderRight: "2px solid " + colors.brown,
        },
        marked: {
            cursor: "default",
            backgroundColor: colors.brown,
            color: colors.white
        },
        threeDots: {
            cursor: "default",
            textDecoration: "none"
        }
    },
    tag: {
        padding: "3px 13px",
        marginLeft: "5px",
        marginTop: "5px",
        fontSize: "14px",
        background: colors.brown,
        color: colors.white,
        float: "right",
        borderRadius: "10px"
    },
    tagDelete: {
        borderLeft: "1px solid " + colors.white,
        padding: "0px 10px 0px 7px",
        marginLeft: "7px",
        cursor: "pointer",
        opacity: fadedOp
    },
    XButton: {
        textAlign: "left",
        fontSize: "15px",
        color: colors.lightRed,
        fontWeight: "bold",
        cursor: "pointer"
    },
    autosuggest: {
        suggestionsList: {
            margin: 0,
            padding: 0,
            listStyleType: 'none',
            border: "0px solid " + colors.black,
            borderWidth: "0px 1px 1px 1px",
            borderRadius: "0px 0px 6px 6px",
            maxHeight: "300px",
            overflowY: "auto"
        },
        suggestionHighlighted: {
            backgroundColor: colors.darkGray
        },
    },
    editIcon: {
        width: "32px",
        padding: "5px",
        margin: "0 auto",
        cursor: "pointer"
    },
    notification: {
        padding: "20px",
        position: "fixed",
        top: "100px",
        right: "-3px",
        borderRadius: "5px 0 0 5px",
    },
    adminPageEntry: {
        link: {
            padding: "25px"
        },
        innerDiv: {
            border: "3px solid " + colors.brown,
            padding: "50px",
            fontSize: "22px"
        }
    },

    // by page
    chooseSchool: {

        img: {
            height: "100px",
        },
        text: {
            padding: "17px 0 0 0",
            height: "100px",
            fontSize: "45px"
        }
    },

    memberInfo: {
        statusDot: {
            width: '23px',
            height: '23px',
            background: "black",
            borderRadius: "100%",
            margin: "0 auto",
            marginBottom: '10px',
        },
        dictStatusDot: {
            width: '15px',
            height: '15px',
            display: 'inline-block',
            background: "transparent",
            borderRadius: "100%",
            margin: '0 10px 0 5px'
        },
        pending: { background: "#a0a0a0" },
        canceled: { background: "black" },
        pastDue: { background: "red" },
        done: { background: "#3dca20" },
        isFromAppeal: { border: "4px solid " + colors.darkTurquoise},
    },

    schedulePlan: {
        rowLabel: {
            background: "#d3d3d3",
            textAlign: "center"
        },
        hourCell: {
            float: "right",
            border: "solid " + colors.black,
            borderWidth: "0px 1px 1px 0px",
            padding: "10px"
        },
        caseAssignmentBox: {
            padding: "10px",
            backgroundColor: colors.darkGray,
        },
        mostLeftCell: {
            borderWidth: "0px 1px 1px 1px",
        },
        titleCell: { // Should extend hourCell's style
            borderWidth: "1px 1px 1px 0px",
            textAlign: "center"
        },
        mostLeftTitleCell: {
            borderWidth: "1px"
        },
        hourAction: {
            fontSize: "14px",
            cursor: "pointer",
            color: colors.white,
            padding: "4px 0px",
            textAlign: "center",
            marginBottom: "4px"
        },
    },

    header: {
        topLine: {
            backgroundColor: mainColor,
            position: "absolute",
            top: "0px",
            right: "0px",
            width: "100%",
            height: "5px",
            margin: "0",
            border: "none"
        },
        logo: {
            container: {
                // -webkit-box-shadow: 0px 0px 7px -1px rgba(0,0,0,0.43);
                // -moz-box-shadow: 0px 0px 7px -1px rgba(0,0,0,0.43);
                position: "absolute",
                top: "22px",
                borderRadius: "12px",
            },
            image: {
                height: "70px",
                padding: "15px",
            }
        }
    }
};
