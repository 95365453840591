import React from 'react';
import PropTypes from 'prop-types';

import Styles from "../../css";


/* Generate styles */
const style = Styles.combineStyles(
    Styles.chooseSchool.img,
    Styles.fonts.title,
    {fontFamily: "reg-font"},
    Styles.general.noMarginPadding
);
const imgStyle = Styles.combineStyles(
    Styles.chooseSchool.img,
    Styles.general.noMarginPadding
);

/**
 * This component represents a link in the school choosing page
 */
const SchoolLink = ({schoolHebName, schoolName, backColor}) =>
    <a href={schoolName + "/submit"}>
        <div style={ style } className="col-xs-12 col-md-4">

            <div style={
                Styles.combineStyles(Styles.chooseSchool.text, {backgroundColor: backColor})
            } className="col-xs-12">{schoolHebName}</div>

        </div>
    </a>

SchoolLink.propTypes = {
    schoolHebName: PropTypes.string,
    schoolName: PropTypes.string.isRequired,
    backColor: PropTypes.string /* The background color of the link */
}

SchoolLink.defaultProps = {
    backColor: Styles.colors.darkGray
}


module.exports = SchoolLink;
