import React from 'react';
import PropTypes from "prop-types";
import Styles from "../../css";

const backOp = "0.2";
const borderOp = "0.33";

class Temperature {
    constructor(name, back, border) {
        this.name = name;
        this.back = this.hexValueToRgbaFormat(back, backOp);
        this.border = this.hexValueToRgbaFormat(border, borderOp);

        this.style = {
            backgroundColor: this.back,
            border: "3px solid " + this.border,
        }
    }

    hexValueToRgbaFormat(hexString, opcacity) {
        let ret = "rgba(";

        // If starts with #, remove it
        if(hexString.charAt(0) == "#")
            hexString = hexString.substring(1);

        // add all numbers
        for (var i = 0; i < 3; i++){
            const nextHex = hexString.substring(0, 2); // get next hex value
            ret += parseInt(nextHex, 16) + ",";

            hexString = hexString.substring(2); // remove the first two characters
        }

        return ret + opcacity + ")";
    }
}

const naturalTemp = new Temperature("natural", Styles.mainColor, Styles.colors.brown);
const goodTemp = new Temperature("good", Styles.colors.lightGreen, Styles.colors.darkTurquoise);
const badTemp = new Temperature("bad", Styles.colors.lightRed, Styles.colors.bordo);


const baseStyle = Styles.combineStyles(
    Styles.fonts.commonFont,
    Styles.notification
);

const Notification = (props) => {
    // Set the color-set (temperature) of the notification
    let temperature = naturalTemp;
    if (props.temperature == goodTemp.name)
        temperature = goodTemp;
    else if (props.temperature == badTemp.name)
        temperature = badTemp;

    // Render its colors into the style
    const renderStyle = Styles.combineStyles(
        baseStyle,
        temperature.style,
    );

    const messageArray = props.children.toString().split("\n");

    // Render component
    return (
        <div style={ renderStyle }>
            {
                messageArray.map((line, i) => <span key={i}>{line}<br/></span>)
            }
        </div>
    );
}

Notification.propTypes = {
    temperature: PropTypes.string, // "good", "bad" or "natural"
                                   // The background color of the box will be determined
                                   //  by this value
    isMultiLine: PropTypes.bool,    // If the message should have multiple lines. If it does, each
                                    // occurrence of "\n" in the body will be parsed to a line
}

Notification.defaultProps = {
    temperature: naturalTemp.name,
    isMultiLine: false
}


export default Notification;
