import React from 'react';
import Styles from "../../css";

const style = Styles.combineStyles(
    Styles.fonts.commonFont,
    Styles.fonts.title,
    Styles.fonts.subtitle
);

const Subtitle = (props) =>
    <h3 style={ style }>
        { props.children }
    </h3>


export default Subtitle;
