import React from 'react';
import PropTypes from 'prop-types';
import Styles from "../../css";

let style = Styles.combineStyles(
    Styles.fonts.commonFont,
    Styles.tag
);
let inlineStyle = Styles.combineStyles(
    style,
    {
        float: "none",
        display: "block"
    }
)


/* This component represents a small, deletable tag */
const Tag = (props) => {
    let thisRendersStyle = style;

    if(!props.useFloatCSS)
        thisRendersStyle = inlineStyle;

    // First, if should be able to delete, add a delete button
    let deleteButton = "";
    if (props.isDeletable) {
        deleteButton = <span style={Styles.tagDelete} onClick={(e) => {
            props.onDelete(props.id)
        }}
        >X</span>;
        // Since the padding is included in the delete button style
        thisRendersStyle = Styles.combineStyles(thisRendersStyle, {paddingRight: "0px"});
    }

    if(props.disabledStyle)
        thisRendersStyle = Styles.combineStyles(thisRendersStyle, {background: "#8a8a8a"});

    return (
        <span style={ thisRendersStyle } className="noselect"
                onClick={_ => props.onClick ? props.onClick(props.id) : _}>
            { deleteButton }
            { props.children }
        </span>
    );
}

Tag.propTypes = {
    isDeletable: PropTypes.bool.isRequired, // Should the user be able to delete it
    onDelete: PropTypes.func, // Will receive the value of the tag
    onClick: PropTypes.func,
    disabledStyle: PropTypes.bool,
    useFloatCSS: PropTypes.bool, // If TagsList's useBootstrapWidthClasses prop is false,
                                // this should be false too
                                // Recommended false if in limited space
    id: PropTypes.string // will be passed to the delete function when deleted
}

Tag.defaultProps = {
    disabledStyle: false,
    useFloatCSS: true
}

export default Tag;
