// For functions
import React from 'react';
import TagList from './components/stateless/TagsList.jsx';
import Styles from "./css";

export default {
    fetch: {
        GENERAL_ERR_MSG: "אירעה שגיאה באחזור מידע נדרש." + "\n" +
        "אנא רעננו את הדף ונסו שוב.",
        GET_SCHOOL_ERR_MSG: "אירעה שגיעה באחזור בית הספר." + "\n" +
        "מנסה שוב...",
        GET_MEMBERS_ERR_MSG: "אירעה שגיעה באחזור חברי הקהילה." + "\n" +
        "מנסה שוב...",
        GET_CASE_ERR_MSG: "אירעה שגיאה באחזור המקרה/מקרים." + "\n" +
        "מנסה שוב...",
        GET_APPEAL_ERR_MSG: "אירעה שגיאה באחזור הערעור." + "\n" +
        "מנסה שוב...",
        GET_PUNISHMENTS_ERR_MSG: "אירעה שגיאה באחזור העונשים." + "\n" +
        "מנסה שוב...",
        GET_UNHANDLED_CASES_ERR_MSG: "אירעה שגיאה באחזור המקרים שלא טופלו" + "\n" +
        "מנסה שוב...",
        GET_UNHANDLED_APPEALS_ERR_MSG: "אירעה שגיאה באחזור הערעורים שלא טופלו" + "\n" +
        "מנסה שוב...",
        SUBMITTED_MSG: "הגשתך נקלטה." + "\n" +
                    "יום טוב!",
        POSTING_WAIT_MSG: "שולח...",
        SAVING_MSG: "שומר...",
        VERIFYING_ADMIN_PASS_MSG: "בודק סיסמא...",
        FAILED_SAVE_ERR_MSG: "נכשלה שמירת הטופס." + "\n" +
        "מנסה שוב...",
        SAVE_SUCC_MSG: "המידע נשמר בהצלחה.",
        GIVING_UP_MSG: "נכשל אחזור המידע." + "\n" +
            "האם אתם מחוברים לאינטרנט?",
        FAILED_UPDATE: "נכשלה שמירת המידע. אנא נסו שוב",
        FAILED_REMOVE: "נכשלה המחיקה. אנא נסו שוב",
        DELETE_SUCCESS: " נמחק בהצלחה ",
        DELETE_FAIL: "המחיקה נחשלה",
    },
    frontEndErrors: {
        NO_SUERS_ERR_START: "שגיאה: יש למלא לפחות ",
        NO_SUERS_ERR_END:  " אחד/ת" + ".\n" +
        "שימו לב כי על מנת להוסיף מישהו/י, יש ללחוץ על שמו/ה" + "\n" +
        "בתיבת ההצעות שמופיעה בזמן הכתיבה.",
        NO_DESCRIPTION_ERR: "התיאור ריק. יש למלאו",
        NO_DETAILS_DESCRIPTION_ERR: "הפירוט ריק. יש למלאו",
        NO_GENERAL_TEXTFIELD_ERR: " ריק\\ה. יש למלאו\\ה ",
        NO_PUNISHMENT_SELECTED: "יש לבחור מהרשימה ",
        NO_APPEAL_DETAILS: "יש למלא לפחות אחד מן השדות הבאים: "
    },
    adminLogin: {
        ADMIN_DIALOG_TITLE: "כניסת ממשק מנהל",
        ADMIN_DIALOG_SUBTITLE: "נא להכניס סיסמת מנהל",
        INVALID_ADMIN_PASS_MSG: "סיסמאת המנהל שהוכנסה שגויה. אנא נסו שנית.",
    },
    titles: {
        ADMIN_PAGE_TITLE: "דף מנהל\\ת",
        MEMBER_INFO_TITLE: "דף חבר\\ת קהילה",
        SHORT_ID: "מספר סידורי",
        HANDLED: "האם טופל",
        DELETE: "מחק/י",
        REMOVE: "הסר/י",
        CHANGE: "שנה\\י",
        ID: "מזהה",
        SAVE: "שמור/שמרי",
        CREATE: "צור/צרי",
        SHOW: "הראה/י",
        HIDE: "הסתר/הסתירי",
        ADD_TO_PUNISHMENTS_START: "הוספת ",
        ADD_TO_PUNISHMENTS_END: " לרשימה",
        REMOVE_FROM_PUNISHMENTS_START: "מחיקת ",
        REMOVE_FROM_PUNISHMENTS_END: " מהרשימה",
        deleteConfirm: {
            TITLE: "אישור מחיקה",
            SUBTITLE: "האם אתם/ן בטוחים שאתם רוצים/רוצות למחוק את ",
        },
        verdictCancelConfirm: {
            TITLE: "אישור ביטול",
            SUBTITLE:  "האם אתם/ן בטוחים שאתם רוצים/רוצות לבטל את ה",
        },

        SYSTEM_NAME: "DCat",
    },
    info: {
        SAVED: "כל השינויים נשמרו",
        NOT_SAVED: "לא כל השינויים נשמרו",
        DELETE_ALERT: "האם את\\ה בטוח\\ה שברצונך למחוק חבר\\ת קהילה זו\\ה?",
        GENERAL_CONFIRM: "האם את\\ה בטוח\\ה שברצונך לשלוח?",
        AUTOCOMPLETE_USAGE_GUIDE: " בכדי לבחור, יש ללחוץ על האפשרות המוצעת.",
        NO_SUGGESTIONS_OBJ: {value: "אין הצעות עבור ", _id: -1},
    },
    searchableView: {
        ALL_FIELDS_STRING: ["_ALL"],
        DEFAULT_MAX_PER_PAGE: 25
    },
    pageControl: {
        RANGE_SIZE: 7,
    },
    MAX_SUMMARY_WORDS: 6,
    classNumbersMatch: {
        1: "גן",
        2: "א'",
        3: "ב'",
        4: "ג'",
        5: "ד'",
        6: "ה'",
        7: "ו'",
        8: "ז'",
        9: "ח'",
        10: "ט'",
        11: "י'",
        12: "י\"א",
        13: "י\"ב",
        14: "צוות",
        15: "חבר\\ת קהילה",
        16: "בן\\ת שירות",
        17: "הורה",
    },
    DAYS_NUMBERS_MATCH: {
        0: "ראשון",
        1: "שני",
        2: "שלישי",
        3: "רביעי",
        4: "חמישי",
        5: "שישי",
        6: "שבת",
    },
    NOTIFICATION_DELAY : 10 * 1000,
    AUTOSAVE_INTERVAL: 1500, // The time we wait after the user stopped changing
                             //  before autosaving
    TIME_UNTIL_FETCH_RETRY: 1000,

    functions: {
        // This function formats a Date object how we need it to
        formatDate: a => ("0"+a.getDate()).slice(-2) + "/" + ("0"+(a.getMonth() + 1)).slice(-2) + "/" + a.getFullYear() +
            ", " + ("0"+a.getHours()).slice(-2) + ":" + ("0"+a.getMinutes()).slice(-2),
        isInAppealMode: _ => location.pathname.indexOf("appeal") !== -1,
        // The following converts an array of members to a tags list
        memberObjToTag: members => members && <div style={{marginRight: "10px"}}>
                                                        <TagList items={members} />
                                                    </div>,
        // The following receives a search term of a member, and then searches for that member
        //  in a given members array. The members array is usually sued/suers.
        searchMembersInArr: (memName, members) => members.find(mem => mem.name.startsWith(memName)),
        searchInString: (needle, haystack) => haystack.indexOf(needle) !== -1,

        casesSignedByFieldCmpFnc: (signedByArr1, signedByArr2) => {
            const is1Signed = signedByArr1 && signedByArr1.length > 0;
            const is2Signed = signedByArr2 && signedByArr2.length > 0;
            if(is1Signed == is2Signed) return 0;
            if(is1Signed) return -1
            return 1
        },

        fadeOutColor: colorInHex => "rgba(" +
            [
                parseInt(colorInHex.substr(1,2), 16),
                parseInt(colorInHex.substr(3,2), 16),
                parseInt(colorInHex.substr(5,2), 16),
                Styles.general.faded.opacity
            ].join(", ") + ")"

    }
};
