const React = require('react');
const ReactDOM = require('react-dom');

import Styles from "./css";
import Layout from "./components/stateless/Layout.jsx";
import SchoolLink from "./components/stateless/SchoolLink.jsx"


class School {
  constructor(schoolName, schoolHebName){
    this.schoolName = schoolName;
    this.schoolHebName = schoolHebName;
  }
}

const schools = [
  new School("Democrati Lev HaSharon", "לב השרון"),
    new School("Democrati Kfar Saba", "כפר סבא"),
    new School("test", "בית בדיקה")
];

ReactDOM.render(
  <Layout title="ברוכים הבאים"
          subtitle="אנא בחרו את בית ספרכם" >
    {
      schools.map((currSchool, i) => <SchoolLink {...currSchool} key={i}
                                        backColor={i%2==0 ? undefined : Styles.colors.lightGray} />)
    }
  </Layout>,
  document.getElementById('app')
);
