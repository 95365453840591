import React from 'react';
import PropTypes from 'prop-types';

import Title from "./Title.jsx";
import Subtitle from "./Subtitle.jsx";
import Styles from "../../css";
import LoadingScreen from "./LoadingScreen.jsx";
import Notification from "./Notification.jsx";
import Constants from "../../Constants";


const Layout = (props) => {
    const {title, subtitle, isLoading} = props;
    let subtitleVar = "";
    let body = "";
    let notification = "";

    // Set notification markup
    if (props.notification != undefined)
        notification = <Notification temperature={props.notification.temperature}>
            {props.notification.message}
        </Notification>

    // If hidden, only show children and notification
    if(props.hide)
        return (
            <span>
                { props.children }
                { notification }
            </span>
        );

    // Set subtitle markup
    if (subtitle != undefined) {
        subtitleVar = <Subtitle>{ subtitle }</Subtitle>;
    }

    // Set loading screen markup
    if (isLoading)
        body = <LoadingScreen />;
    else
        body = props.children;

    return (
        <div>
            <header>
                <title>{ Constants.titles.SYSTEM_NAME + " | " + props.title }</title>
                {
                    props.showBackButton &&
                    <div>
                        <img onClick={props.onBackButtonClick} style={Styles.editIcon} src="/media/back.svg" />
                    </div>
                }
                {/* Below is the top decoration line */}
                <hr style={ Styles.header.topLine }></hr>

                {/* logo */}
                <div style={ Styles.header.logo.container }>
                    <img src={"/media/" + (Constants.functions.isInAppealMode() ? "appealLogo" : "logo") + ".png"} style={ Styles.header.logo.image } />
                </div>

                <Title>{ title }</Title>
                { subtitleVar }
                <hr />
            </header>
            <main className="container">
                { body }
            </main>
            { notification }
        </div>
    );

}

Layout.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    isLoading: PropTypes.bool.isRequired, // whether to display the loading screen
    notification: PropTypes.object, // will be undefined, or an object with
                                    //  "temperature" and "message"
    hide: PropTypes.bool, // Whether to display or not

    showBackButton: PropTypes.bool,
    onBackButtonClick: PropTypes.func,
}

Layout.defaultProps = {
    title: "כלי וועדות המשמעת",
    subtitle: undefined,
    notification: undefined,
    isLoading: false,
    hide: false,
    showBackButton: false
}


export default Layout;
