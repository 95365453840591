import React from 'react';
import Styles from "../../css";

const style = Styles.combineStyles(
    Styles.fonts.commonFont,
    Styles.fonts.title
);

const Title = (props) =>
    <h1 style={ style }>
        { props.children }
    </h1>


export default Title;
