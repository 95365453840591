import React from 'react';
import PropTypes from 'prop-types';
import Styles from "../../css";

import Tag from "./Tag.jsx";


const TagList = ({areDeletables, onDelete, onClick, useBootstrapWidthClasses, disabledStyle, items}) =>
    <div className={useBootstrapWidthClasses ? "row" : ""}>
        {
            items.map((item, i) =>
                <Tag isDeletable={areDeletables} onClick={onClick} onDelete={onDelete}
                     disabledStyle={disabledStyle}
                     useFloatCSS={useBootstrapWidthClasses}
                     id={item._id} key={i}>{item.value ? item.value : item.name}</Tag>
            )
        }
    </div>


// Component prop types
TagList.propTypes = {
    areDeletables: PropTypes.bool,
    onDelete: PropTypes.func,
    onClick: PropTypes.func,
    disabledStyle: PropTypes.bool,
    useBootstrapWidthClasses: PropTypes.bool, // recommended false if in limited space
    items: PropTypes.array.isRequired // An array of objects, which must contain either the
                                      // "value" or "name" keys. This will be their displayed string.
                                      // must also contain an "_id", which will be passed
                                      // to the "delete" event
}

TagList.defaultProps = {
    areDeletables: false,
    disabledStyle: false,
    useBootstrapWidthClasses: true
}


export default TagList;
