import React from 'react';
import Styles from "../../css";


let style = Styles.combineStyles(
    Styles.fonts.commonFont,
    Styles.fonts.title
);

const LoadingScreen = () =>
    <div style={ style }>
        טוען...
    </div>


export default LoadingScreen;
